import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { ISoundUploadRequest, ISubscriptionPrices } from '@interfaces';
import { catchError, map, Observable, throwError } from 'rxjs';

export interface IHttpResponse {
  data: {
    message?: string;
    error?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  private apiUrl = environment.backendUrl;

  constructor(private http: HttpClient, private authService: AuthService) {}

  private getAuthHeaders(
    includeContentType: boolean = true,
    shareToken?: string,
  ): HttpHeaders {
    let token: string | null = null;
    if (shareToken) {
      token = shareToken;
    } else {
      token = this.authService.getFullToken();
    }
    if (!token) {
      throw new Error('No token found');
    }

    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    if (includeContentType) {
      headers = headers.set('Content-Type', 'application/json');
    }

    return headers;
  }

  uploadSound(sound: ISoundUploadRequest) {
    const formData = new FormData();
    formData.append('title', sound.title);
    formData.append('file', sound.file);

    // Get the headers without the Content-Type header
    const headers = this.getAuthHeaders(false);

    return this.http.post(`${this.apiUrl}/upload-sound`, formData, {
      headers,
    });
  }

  fetchAudio(audioId: string, shareToken?: string): Observable<Blob> {
    const headers = this.getAuthHeaders(false, shareToken);
    const url = `${this.apiUrl}/uploads/${audioId}.wav`;
    return this.http.get(url, { responseType: 'blob', headers });
  }

  fetchPricing(): Observable<ISubscriptionPrices[]> {
    return this.http
      .get<ISubscriptionPrices[]>(`${environment.wordPressPricingApi}`)
      .pipe(
        map((res) => {
          const prices = res.map((price) => {
            const priceDetails: ISubscriptionPrices = {
              rd_plan_name: price['title']['rendered'],
              ...price['acf'],
            };
            return priceDetails;
          });
          return prices;
        }),
      );
  }

  resendVerificationEmail(): Observable<IHttpResponse> {
    const headers = this.getAuthHeaders();
    return this.http
      .post<IHttpResponse>(`${this.apiUrl}/resend-verification-email`, null, {
        headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error resending verification email', error);
          return throwError(() => error);
        }),
      );
  }

  confirmEmail(token: string): Observable<IHttpResponse> {
    const headers = this.getAuthHeaders();
    return this.http
      .post<IHttpResponse>(
        `${this.apiUrl}/confirm-email`,
        { token },
        { headers },
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error confirming email', error);
          return throwError(() => error);
        }),
      );
  }
}
